import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { useInView } from 'react-intersection-observer'

const SlideMobile = ({ className, i, onChange }) => {

	const [ref, inView] = useInView({threshold: 0.51})

	useEffect(() => {
		if(inView && onChange) {
			onChange(i)
		}
	}, [inView, onChange])

	return (
		<div ref={ref}>
			<Wrap className={className}>
			
			</Wrap>
		</div>
	)
}

const Wrap = styled.div`
	height: 100vh;
`

SlideMobile.propTypes = {
	className: PropTypes.string,
	i: PropTypes.number,
	onChange: PropTypes.func,
}

export default SlideMobile